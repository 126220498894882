<template>
    <div class="comment_block" 
         :ref="`commentBlock_${related_object}`"
         :class="mainClass || 'w-full'">
        <CommentInput
            v-if="allowComments"
            ref="mainInput"
            :related_object="related_object"
            :model="model"
            :createFounder="createFounder"
            :oneUpload="oneUpload"
            :showEmoji="showEmoji"
            :showFileUpload="showFileUpload"
            :pushNewComment="pushNewComment"
            :blockLeft="blockLeft"
            :setBlockLeft="setBlockLeft"
            :getModalContainer="getModalContainer"
            :showUsers="showUsers"
            v-element-visibility="onElementVisibility" />
        <div
            v-if="list.results.length"
            class="pt-6">
            <div 
                v-for="(group, index) in sortedList" 
                :key="index" 
                class="comment_group">
                <GroupLabel :group="group" />
                <transition-group name="comment_list" tag="div">
                    <CommentItem
                        v-for="item in group.data"
                        :key="item.id"
                        :user="user"
                        :item="item"
                        :bodySelector="bodySelector"
                        :addTaskCheck="addTaskCheck"
                        :toggleNewCommentCount="toggleNewCommentCount"
                        :showEmoji="showEmoji"
                        :updateNewComment="updateNewComment"
                        :shareCheck="shareCheck"
                        :setBlockLeft="setBlockLeft"
                        :deleteComment="deleteComment"
                        :blockLeft="blockLeft"
                        :closeMainInput="closeMainInput"
                        :showFileUpload="showFileUpload"
                        :extendDrawerZIndex="extendDrawerZIndex"
                        :showUsers="showUsers"
                        :getModalContainer="getModalContainer"
                        :related_object="related_object"
                        :pushNewComment="pushNewComment"
                        :updateComment="updateComment"
                        :model="model" />
                </transition-group>
            </div>
        </div>
        <template v-if="commentLimit">
            <div 
                v-if="list.count > limitCount && list.next"
                class="flex justify-center">
                <a-button 
                    type="ui" 
                    ghost
                    :loading="loading" 
                    @click="getCommentLimit()">
                    {{ $t('comment.loadMore', { count: list.count - visibleComment }) }}
                </a-button>
            </div>
        </template>
        <infinite-loading 
            v-else
            ref="comment_infinity"
            @infinite="getCommentInfinite"
            v-bind:distance="10">
            <div 
                slot="spinner"
                class="flex items-center justify-center mt-3">
                <a-spin />
            </div>
            <div slot="no-more"></div>
            <div slot="no-results"></div>
        </infinite-loading>
        <CommentModal 
            :model="model" 
            :related_object="related_object"
            :pPushNewComment="pushNewComment"
            :pDeleteComment="deleteComment"
            :allowComments="allowComments"
            :createFounder="createFounder"
            :oneUpload="oneUpload"
            :blockLeft="blockLeft"
            :setBlockLeft="setBlockLeft"
            :spliceComment="spliceComment"
            :showUsers="showUsers"
            :showEmoji="showEmoji"
            :getModalContainer="getModalContainer"
            :showFileUpload="showFileUpload" />
        <transition v-if="list.results.length" name="slide-up-fade">
            <div 
                v-if="!inputVisible" 
                class="new_comment">
                <a-badge 
                    :count="newComment"
                    :number-style="{
                        backgroundColor: '#1c65c0'
                    }">
                    <a-button
                        shape="circle"
                        flaticon
                        icon="fi-rr-angle-small-up"
                        @click="parentTopScroll()" />
                </a-badge>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import CommentInput from './CommentInput.vue'
import CommentItem from './CommentItem.vue'
import GroupLabel from './GroupLabel.vue'
import InfiniteLoading from 'vue-infinite-loading'
import CommentModal from './CommentModal.vue'
import eventBus from './eventBus'
import { vElementVisibility } from '@vueuse/components'
import { socketEmitJoin, socketEmitLeave } from '@/utils/socketUtils.js'
export default {
    name: 'Comments',
    components: {
        CommentInput,
        CommentItem,
        InfiniteLoading,
        GroupLabel,
        CommentModal
    },
    directives: {
        ElementVisibility: vElementVisibility
    },
    props: {
        bodySelector: {
            type: String,
            default: 'body'
        },
        related_object: { // id элемента к которому привязываем комментарии, можем привязать к чему угодно
            type: [String, Number],
            required: true
        },
        model: { // Может быть news, file, club
            type: String,
            required: false
        },
        extendDrawerZIndex: {
            type: Number,
            default: 1000
        },
        mainClass: {
            type: String,
            default: ''
        },
        allowComments: {
            type: Boolean,
            default: true
        },
        showUsers: {
            type: Boolean,
            default: true
        },
        oneUpload: {
            type: Boolean,
            default: false
        },
        createFounder: {
            type: Boolean,
            default: true
        },
        showEmoji: {
            type: Boolean,
            default: true
        },
        showFileUpload: {
            type: Boolean,
            default: true
        },
        addTaskCheck: {
            type: Boolean,
            default: true
        },
        shareCheck: {
            type: Boolean,
            default: true
        },
        modalContainer: {
            type: Boolean,
            default: true
        },
        commentLimit: {
            type: Boolean,
            default: false
        },
        limitCount: {
            type: Number,
            default: 2
        },
        injectContainer: {
            type: Boolean,
            default: false
        },
        injectContainerSelector: {
            type: Function,
            default: () => document.body
        }
    },
    computed: {
        ...mapState({
            user: state => state.user.user
        }),
        sortedList() {
            const res = [...this.list.results].map(item => {
                return {
                    ...item,
                    key: this.$moment(item.created_at).format('YYYY-MM-DD'),
                    auid: item.author.id
                }
            }).reduce((accumulator, currentValue, currentIndex, array, key = currentValue.key) => {
                const keyObjectPosition = accumulator.findIndex((item) => item.key === key)
                if (keyObjectPosition >= 0) {
                    accumulator[keyObjectPosition].data.push(currentValue)
                    return accumulator    
                } else {
                    return accumulator.concat({ data: [currentValue], key: key })
                }
            }, [])
            return res
        }
    },
    data() {
        return {
            loading: false,
            scrollParent: null,
            page: 0,
            slice_count: 0,
            newComment: 0,
            visibleComment: 0,
            inputVisible: true,
            blockLeft: false,
            page_size: 15,
            limitLoaded: false,
            limitLoadedNext: false,
            list: {
                results: [],
                count: 0,
                next: true
            }
        }
    },
    created() {
        const blockLeft = localStorage.getItem('blockLeft')
        if(blockLeft) {
            this.blockLeft = JSON.parse(blockLeft)
        }
        if(this.commentLimit) {
            this.getCommentLimit()
        }
    },
    sockets: {
        create_comment({ data }) {
            if(data.related_object === this.related_object)
                this.pushNewComment({
                    ...data,
                    newComment: this.user?.id === data.author?.id ? false : true,
                    newVisible: this.user?.id === data.author?.id ? false : true
                })
        },
        update_comment({data}) {
            if(data.related_object === this.related_object)
                this.updateComment(data)
        },
        delete_comment({ data }) {
            this.spliceComment(data)
        }
    },
    methods: {
        setBlockLeft() {
            this.blockLeft = !this.blockLeft
            localStorage.setItem('blockLeft', JSON.stringify(this.blockLeft))
        },
        closeMainInput() {
            this.$refs.mainInput.closeEditorHandler()
        },
        getModalContainer() {
            if(this.modalContainer) {
                if(this.injectContainer) {
                    return this.injectContainerSelector()
                } else
                    return this.$refs[`commentBlock_${this.related_object}`]
            } else
                return document.body
        },
        onElementVisibility(e) {
            this.inputVisible = e
        },
        getScrollParent(elm = this.$el) {
            let result;

            if (!result) {
                if (elm.tagName === 'BODY') {
                    result = window;
                } else if (['scroll', 'auto'].indexOf(getComputedStyle(elm).overflowY) > -1) {
                    result = elm;
                } else if (elm.hasAttribute('infinite-wrapper') || elm.hasAttribute('data-infinite-wrapper')) {
                    result = elm;
                }
            }

            return result || this.getScrollParent(elm.parentNode);
        },
        toggleNewCommentCount(type, id = null) {
            if(type === 'add') {
                this.newComment += 1
            }
            if(type === 'min') {
                if(this.newComment > 0)
                    this.newComment -= 1
                if(id) {
                    const index = this.list.results.findIndex(f => f.id === id)
                    if(index !== -1)
                        this.$delete(this.list.results[index], 'newVisible')
                }
            }
        },
        updateNewComment(id) {
            const index = this.list.results.findIndex(f => f.id === id)
            if(index !== -1)
                this.$delete(this.list.results[index], 'newComment')
        },
        updateComment(data) {
            if(this.list.results.length) {
                const index = this.list.results.findIndex(f => f.id === data.id)
                if(index !== -1)
                    this.$set(this.list.results, index, data)
            }
        },
        pushNewComment(data) {
            this.list.results.unshift({
                ...data,
                created_at: this.$moment(data.created_at).add(-1, 'seconds').format()
            })
            this.list.count += 1
            if(this.commentLimit) {
                if(this.limitLoadedNext) {
                    this.slice_count += 1
                }
            } else {
                this.slice_count += 1
            }
        },
        spliceComment(id) {
            const index = this.list.results.findIndex(f => f.id === id)
            if(index !== -1) {
                this.list.results.splice(index, 1)
                this.list.count -= 1
                if(this.slice_count > 0)
                    this.slice_count -= 1
            }
            const cParent = this.list.results.filter(f => f.parent?.id === id)
            if(cParent?.length) {
                cParent.forEach(item => {
                    const cIndex = this.list.results.findIndex(f => f.id === item.id)
                    if(cIndex !== -1) {
                        this.$set(this.list.results[cIndex].parent, 'text', this.$t('comment.commentDeleted')) // Комментарий удалён / Пікір жойылды
                    }
                })
            }
        },
        async deleteComment(id) {
            try {
                await this.$http.post('/comments/delete/', { id })
            } catch(e) {
                console.log(e)
                this.$message.error(this.$t('comment.deleteError')) // Ошибка удаления / Жою кезінде қате
            }
        },
        async getCommentLimit() {
            try {
                this.page += 1
                this.loading = true

                const params = {
                    page: this.page,
                    reverse: true
                }

                if(this.limitLoaded) {
                    if(this.page > 1) {
                        if(this.slice_count)
                            params.slice_count = this.slice_count
                    }
                    params.page_size = 15
                } else {
                    params.page_size = this.limitCount || 2
                }

                if(this.model)
                    params.model = this.model
                if(this.related_object)
                    params.related_object = this.related_object

                const { data } = await this.$http.get('/comments/', { params })

                if(data) {
                    this.list.count = data.count
                    this.list.next = data.next
                }
                if(data?.results?.length) {
                    if(this.limitLoaded && this.page === 1) {
                        if(!this.limitLoadedNext)
                            this.limitLoadedNext = true
                        this.list.results = data.results
                    } else {
                        this.list.results = this.list.results.concat(data.results)
                    }
                    this.visibleComment = this.list.results.length
                }
                if(!this.limitLoaded) {
                    this.page = 0
                    this.limitLoaded = true
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        },
        async getCommentInfinite($state) {
            if(!this.loading && this.list.next) {
                try {
                    this.loading = true
                    this.page += 1

                    const params = {
                        page: this.page,
                        page_size: 15,
                        reverse: true
                    }

                    if(this.slice_count)
                        params.slice_count = this.slice_count
                    if(this.model)
                        params.model = this.model
                    if(this.related_object)
                        params.related_object = this.related_object

                    const { data } = await this.$http.get('/comments/', { params })

                    if(data) {
                        this.list.count = data.count
                        this.list.next = data.next
                    }

                    if(data?.results?.length)
                        this.list.results = this.list.results.concat(data.results)
                        
                    if(this.list.next)
                        $state.loaded()
                    else
                        $state.complete()
                } catch(e) {
                    console.log(e)
                } finally {
                    this.loading = false
                }
            } else {
                $state.complete()
            }
        },
        scrollHandler(e) {
            // console.log(e, 'scrollHandler')
        },
        parentTopScroll() {
            if(this.scrollParent) {
                this.newComment = 0
                const obj = this.$refs[`commentBlock_${this.related_object}`]
                const posX = obj.offsetTop
                this.scrollParent.scrollTop = posX - 100
                // this.scrollParent.scroll({top:0, behavior:'smooth'})
            }
        }
    },
    mounted() {
        this.scrollParent = this.getScrollParent()
        socketEmitJoin(`detail_${this.related_object}`)
        /*setTimeout(() => {
            this.scrollHandler()
            this.scrollParent.addEventListener('scroll', this.scrollHandler, evt3rdArg)
        }, 1)*/

        eventBus.$on('set_comment_shaking', cid => {
            if(cid) {
                const index = this.list.results.findIndex(f => f.id === cid)
                if(index !== -1) {
                    if(this.list.results[index].shaking) {
                        this.$delete(this.list.results[index], 'shaking')
                        setTimeout(() => {
                            this.$set(this.list.results[index], 'shaking', true)
                        }, 5)
                    } else {
                        this.$set(this.list.results[index], 'shaking', true)
                    }
                }
            }
        })
    },
    beforeDestroy() {
        socketEmitLeave(`detail_${this.related_object}`)
        eventBus.$off('set_comment_shaking')
    }
}
</script>

<style lang="scss" scoped>
.slide-up-fade-enter-active {
  transition: all .2s ease;
}
.slide-up-fade-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-up-fade-enter, .slide-up-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
.comment_block{
    position: relative;
}
.comment_group{
    &:not(:last-child){
        margin-bottom: 18px;
    }
    .comment_list-item {
        display: inline-block;
        margin-right: 10px;
    }
    .comment_list-enter-active, .comment_list-leave-active {
        transition: all 0.3s;
    }
    .comment_list-enter, .comment_list-leave-to {
        opacity: 0;
        transform: translateY(30px);
    }
}
.new_comment{
    position: sticky;
    bottom: 40px;
    height: 0px;
    z-index: 100;
    display: flex;
    justify-content: flex-end;
    &::v-deep{
        .ant-badge{
            .ant-badge-count{
                font-size: 10px !important;
                min-width: 17px;
                height: 17px;
                padding: 0 6px;
                line-height: 17px;
                right: initial;
                left: 50%;
                margin-left: -17px;
                &.ant-badge-multiple-words{
                    margin-left: -23px;
                }
            }
        }
    }
}
</style>